.Footer {
  margin-top: 64px;
  background: #302C6E;
  padding: 40px 32px 16px 32px;
  figure {
    height: 50px;
    img {
      height: 100%;
    }
  }
  .footer-items {
    p {
      color: #ffffff;
      font-size: 1rem;
      &.logo {
        font-size: 2rem;
      }
    }
    a {
      color: #ffffff;
      font-size: 1rem;
    }
  }
  .powered-by {
    color: #ffffff;
    margin-top: 32px;
    p {
      font-size: .8rem;
      margin-bottom: .2rem;
    }
    a {
      color: #ffffff;
    }
  }

  hr {
    background-color: #ffffff;
  }
}