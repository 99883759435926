.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@font-face {
  font-family: "Dosis-Bold";
  src: url("./assets/fonts/Dosis-Bold.ttf");
}
@font-face {
  font-family: "Dosis-Medium";
  src: url("./assets/fonts/Dosis-Medium.ttf");
}
@font-face {
  font-family: "Dosis-Light";
  src: url("./assets/fonts/Dosis-Light.ttf");
}

@font-face {
  font-family: "Open-Bold";
  src: url("./assets/fonts/Dosis-Bold.ttf");
}
@font-face {
  font-family: "Open-Regular";
  src: url("./assets/fonts/Dosis-Regular.ttf");
}
@font-face {
  font-family: "Open-Light";
  src: url("./assets/fonts/Dosis-Light.ttf");
}
@font-face {
  font-family: "Monserrat-Bold";
  src: url("./assets/fonts/Montserrat-Bold.ttf");
}
@font-face {
  font-family: "Monserrat-Medium";
  src: url("./assets/fonts/Montserrat-Medium.ttf");
}
@font-face {
  font-family: "Monserrat-Semi-Bold";
  src: url("./assets/fonts/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: "Monserrat-Light";
  src: url("./assets/fonts/Montserrat-Light.ttf");
}

.dosis-bold {
  font-family: Dosis-Bold;
}
.dosis-medium {
  font-family: Dosis-Medium;
}
.dosis-light {
  font-family: Dosis-Light;
}

.open-bold {
  font-family: Open-Bold;
}
.open-regular {
  font-family: Open-Regular;
}
.open-light {
  font-family: Open-Light;
}
.monserrat-bold {
  font-family: Monserrat-Bold;
}
.monserrat-medium {
  font-family: Monserrat-Medium;
}
.monserrat-semi-bold {
  font-family: Monserrat-Semi-Bold;
}
.monserrat-light {
  font-family: Monserrat-Light;
}

p {
  font-family: Open-Regular;
  font-size: 1.1rem;
}

h2 {
  font-size: 2.5rem;
}